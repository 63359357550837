<template>
  <b-sidebar
    id="add-new-organisation-sidebar"
    :visible="isAddNewOrganisationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-organisation-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Organisatie aanmaken
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name-->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Naam"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="organisationData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Street-->
          <validation-provider
            #default="validationContext"
            name="Street"
            rules="required"
          >
            <b-form-group
              label="Straat"
              label-for="street"
            >
              <b-form-input
                id="street"
                v-model="organisationData.street"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Housenumber-->
          <validation-provider
            #default="validationContext"
            name="houseNumber"
            rules="required"
          >
            <b-form-group
              label="Huisnummer"
              label-for="houseNumber"
            >
              <b-form-input
                id="houseNumber"
                v-model="organisationData.housenumber"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- City-->
          <validation-provider
            #default="validationContext"
            name="city"
            rules="required"
          >
            <b-form-group
              label="Plaats"
              label-for="city"
            >
              <b-form-input
                id="city"
                v-model="organisationData.city"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- District-->
          <validation-provider
            #default="validationContext"
            name="district"
            rules="required"
          >
            <b-form-group
              label="District"
              label-for="district"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="organisationData.district"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="districtOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="active"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider
            #default="validationContext"
            name="country"
            rules="required"
          >
            <b-form-group
              label="Land"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="organisationData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="active"
                @change="countryChanged(organisationData.country)"
                @input="countryChanged(organisationData.country)"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email-->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required|email"
          >
            <b-form-group
              label="E-mailadres"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="organisationData.email"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phonenumber-->
          <validation-provider
            #default="validationContext"
            name="phoneNumber"
            rules="required"
          >
            <b-form-group
              label="Telefoonnummer"
              label-for="phoneNumber"
            >
              <b-form-input
                id="phoneNumber"
                v-model="organisationData.phoneNumber"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider
            #default="validationContext"
            name="active"
            rules="required"
          >
            <b-form-group
              label="Status"
              label-for="active"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="organisationData.active"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="active"
                disabled="disabled"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Aanmaken
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuleren
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { alphaNum, email, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewOrganisationSidebarActive',
    event: 'update:is-add-new-organisation-sidebar-active',
  },
  props: {
    isAddNewOrganisationSidebarActive: {
      type: Boolean,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    countryOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    districtOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    resolveDistrictForCountry: {
      type: Function,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  methods: {
    countryChanged(countryCode) {
      this.organisationData.district = ''
      this.districtOptions = this.resolveDistrictForCountry(countryCode)
    },
  },
  setup(props, { emit }) {
    const blankOrganisationData = {
      name: '',
      active: 0,
      createTimestamp: '',
      country: 'SR',
      district: '',
      street: '',
      phoneNumber: '',
      housenumber: '',
      city: '',
      bankAccount: '',
    }

    const organisationData = ref(JSON.parse(JSON.stringify(blankOrganisationData)))
    const resetOrganisationData = () => {
      organisationData.value = JSON.parse(JSON.stringify(blankOrganisationData))
    }

    const onSubmit = () => {
      store.dispatch('app-organisations/addOrganisation', organisationData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-organisation-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetOrganisationData)

    return {
      organisationData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-organisation-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
