<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <organisation-list-add-new
      :is-add-new-organisation-sidebar-active.sync="isAddNewOrganisationSidebarActive"
      :status-options="statusAddOptions"
      :country-options="countryOptions"
      :district-options="districtOptions"
      :resolve-district-for-country="resolveDistrictForCountry"
      @refetch-data="refetchData"
    />

    <template>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <template>
            <!-- Table Container Card -->
            <b-card
              no-body
            >
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Entries</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Zoeken op naam..."
                      />

                      <b-button
                        v-if="$can('create', 'organisation')"
                        variant="primary"
                        @click="isAddNewOrganisationSidebarActive = true"
                      >
                        <span class="text-nowrap">Toevoegen</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
                <b-table
                  ref="refOrganisationListTable"
                  :items="fetchOrganisations"
                  responsive
                  :fields="tableColumns"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDirDesc"
                  show-empty
                  empty-text="Geen overeenkomende records gevonden"
                  class="position-relative organisations-table"
                >
                  <!-- Column: Id -->
                  <template #cell(id)="data">
<!--                    <b-link-->
<!--                      :to="{ name: 'apps-organisation-edit', params: { id: data.item.id }}"-->
<!--                      class="font-weight-bold"-->
<!--                    >-->
                      #{{ data.value }}
<!--                    </b-link>-->
                  </template>

                  <!-- Column: Relations -->
                  <template #cell(name)="data">
                    <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          size="32"
                          :src="data.item.avatar"
                          :text="avatarText(data.item.name)"
                        />
                      </template>
                      <b-link
                        class="font-weight-bold d-block text-nowrap"
                      >
                        {{ data.item.name }}
                      </b-link>
                      <div class="text-muted">
                        {{ data.item.email }}
                      </div>
                    </b-media>
                  </template>

                  <!-- Column: Status -->
                  <template #cell(status)="data">
                    <b-badge
                      pill
                      :variant="`light-${resolveUserStatusVariant(data.item.active)}`"
                      class="text-capitalize"
                    >
                      {{ resolveUserStatus(data.item.active) }}
                    </b-badge>
                  </template>

                  <template #cell(createTimestamp)="data">
                    <div class="text-nowrap">
                      <span class="align-text-top text-capitalize">{{formatDateTime(data.value)}}</span>
                    </div>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">

                    <div class="text-nowrap">
                      <b-link
                        v-if="$can('update', 'organisation')"
                        :to="{ name: 'apps-organisation-edit', params: { id: data.item.id }}"
                        :id="`organisation-view-action-row-${data.item.id}`"
                      >
                        <feather-icon
                          :id="`organisation-row-${data.item.id}-preview-icon`"
                          class="cursor-pointer mx-1"
                          icon="EyeIcon"
                          size="16"
                        />
                      </b-link>
                      <b-tooltip
                          :target="`organisation-view-action-row-${data.item.id}`"
                          placement="top"
                      >
                        <p class="mb-0">
                          Aanpassen
                        </p>
                      </b-tooltip>
                      <feather-icon
                        v-if="$can('update', 'organisation')"
                        :id="`organisation-row-${data.item.id}-delete-icon`"
                        class="cursor-pointer"
                        size="16"
                        icon="TrashIcon"
                        @click="confirmOrganisationDeleteText(data.item.id)"
                      />
                      <b-tooltip
                          :target="`organisation-row-${data.item.id}-delete-icon`"
                          placement="top"
                      >
                        <p class="mb-0">
                          Verwijderen
                        </p>
                      </b-tooltip>
                    </div>
                  </template>
                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalOrganisations"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
            </b-card>
          </template>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BLink,
  BOverlay,
  BBadge,
  BPagination,
  BFormInput,
  BMedia,
  BAvatar, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { formatDateTime } from '@core/utils/utils'
import { onUnmounted, ref } from '@vue/composition-api'
import organisationStoreModule from '@/views/pages/organisations/organisationsStoreModule'
import useOrganisationsList from '@/views/pages/organisations/useOrganisationsList'
import OrganisationListAddNew from '@/views/pages/organisations/organisation-add/OrganisationListAddNew.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    OrganisationListAddNew,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BTable,
    BLink,
    BOverlay,
    BBadge,
    BPagination,
    BMedia,
    BAvatar,
    vSelect,
    BTooltip,
  },
  directives: {
    Ripple,
  },
  methods: {
    confirmOrganisationDeleteText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder het!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-organisations/deleteOrganisation', { id })
            .then(() => {
              this.refetchData()
              this.$swal({
                icon: 'success',
                title: 'Verwijderd!',
                text: 'De organisatie is verwijderd.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    const ORGANISATIONS_APP_STORE_MODULE_NAME = 'app-organisations'

    // Register module
    if (!store.hasModule(ORGANISATIONS_APP_STORE_MODULE_NAME)) store.registerModule(ORGANISATIONS_APP_STORE_MODULE_NAME, organisationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANISATIONS_APP_STORE_MODULE_NAME)) store.unregisterModule(ORGANISATIONS_APP_STORE_MODULE_NAME)
    })

    const isAddNewOrganisationSidebarActive = ref(false)

    const {
      fetchOrganisations,
      tableColumns,
      perPage,
      currentPage,
      totalOrganisations,
      dataMeta,
      perPageOptions,
      sortBy,
      refOrganisationListTable,
      resolveUserStatus,
      resolveUserStatusVariant,
      resolveDistrictForCountry,
      refetchData,
      statusFilter,
      showOverlay,
      statusOptions,
      countryOptions,
      districtOptions,
      searchQuery,
      statusAddOptions,
      isSortDirDesc,
    } = useOrganisationsList()

    return {
      isAddNewOrganisationSidebarActive,
      fetchOrganisations,
      tableColumns,
      perPage,
      currentPage,
      totalOrganisations,
      dataMeta,
      perPageOptions,
      sortBy,
      refOrganisationListTable,
      resolveUserStatus,
      resolveUserStatusVariant,
      resolveDistrictForCountry,
      refetchData,
      statusFilter,
      statusOptions,
      countryOptions,
      districtOptions,
      showOverlay,
      searchQuery,
      avatarText,
      statusAddOptions,
      isSortDirDesc,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }

  .categorie-table{
    .description{
      width:30%;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
